<template>
  <v-app id="inspire">
    
      <v-navigation-drawer v-model="$store.state.drawer" :clipped="$vuetify.breakpoint.lgAndUp" app class="d-print-none print-push-left">
      <v-list dense v-if="data">
        <template>
          <router-link to="/home" class="sidebar-link">
           <v-list-item link >
            <v-list-item-icon>
                <v-icon class="btn-title">mdi-home</v-icon>
            </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Dashboard </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
           </router-link>
         <v-divider></v-divider>
           <div v-for="link in links"
            :key="link.linkname" @click="clear()">
           <router-link :to="{ name:link.link}" class="sidebar-link">
            
          <v-list-item link>
          <v-list-item-icon>
            <v-icon class="btn-title">{{ link.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ link.link_name }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        </router-link>
         <v-divider></v-divider>
      </div>
            
        </template>
      </v-list>
    </v-navigation-drawer>

    <Header :drawer="$store.state.drawer"></Header>
  
    <v-content>
      <v-container fluid> 
             <router-view></router-view> 
      </v-container>
      <!-- <div>Quick-Links RD
        <router-link :to="{name:'employee-type'}">click</router-link>
      </div> -->
    </v-content>
  </v-app>
</template>

<script>
import axios from 'axios'
import Header from "../components/shared/Header";
export default {
  //props: ['para_role_type'],
  props: {
    role:String,
  },
  data() {
      return {
          // rt:this.$route.query.roletype
          data:{},
          parentUrl:'',
          links:null,
          }
  },
  components: {
     Header:Header,
  },
  methods:{
      clear()
  {
  localStorage.removeItem("scheduling_object");
      localStorage.removeItem("companyname");
      localStorage.removeItem("scheduling_id");
  },
    back(){
      this.$router.push('/home');
    }
  },
  watch:{
    
  },

  mounted(){
    
    const paradata={
      role:this.role,
    }
    axios.post("/home/getlinks",paradata)
    .then(res =>{
      //window.console.log("res"+res.data)
      if(res.data.msg=="200"){
        //  console.log(res.data)
          this.links = res.data.Role_links
          this.parentUrl = this.$route.path
      }
      else{
      }
    })
    .catch(error => {
        window.console.log(error)
    })
  } 
}
</script>


<style scoped>
.sidebar-link {
  text-decoration: none;
}
.sidebar-link-item:hover {
  background-color: rgb(181, 186, 190) !important;
}
@media print {
  .print-push-left{
    margin-left: -256px;
    display: none !important;
  }
}

    .btn-title{
        text-transform: uppercase;
      background: linear-gradient(to right, #30CFD0 0%, #330867 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      
    }
    
</style>