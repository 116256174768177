<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-app>
      <transition>
        <router-view />
      </transition>
    </v-app>
    <!--
    <div
      id="quick_link_slider"
      v-if="quick_links"
      class="quick-link-slider-box "
    >
      <div class="quick-link-icon no-display">
        <v-icon
          large
          color="orange darken-2"
          class="icon-quick "
          @click="sliderCloseOpen(false)"
          v-if="isClosed"
          title="Quick Links"
          >mdi-arrow-left-bold-box
        </v-icon>
        <v-icon
          large
          color="orange darken-2"
          class="icon-quick "
          @click="sliderCloseOpen(true)"
          v-if="!isClosed"
          title="Quick Links"
          >mdi-arrow-right-bold-box
        </v-icon>
      </div>

      <div 
        style="position: relative; top: -35px; overflow-y: scroll;height:100%"
      >
        <v-list dense>
          <v-subheader style="font-size: 18px"> Quick Links </v-subheader>
          <v-btn
            fab
            dark
            x-small
            class="quick-link-add-btn"
            @click.native="openDialog()"
            style="background-color:#4caf50;"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>

          <v-list-item-group color="primary">
            <router-link
              to="/home"
              class="sidebar-link"
              @click.native="sliderCloseOpen(true)"
            >
              <v-list-item link>
                <v-list-item-icon>
                  <v-icon>mdi-home</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title style="font-size: 1rem"
                    >Home</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </router-link>
            <v-divider></v-divider>

            <div v-for="(qlink, index) in quicklinkslist" :key="index">
              <router-link
                :to="{ name: qlink.link }"
                class="sidebar-link"
                @click.native="sliderCloseOpen(true)"
              >
                <v-list-item link>
                  <v-list-item-icon>
                    <v-icon>{{ qlink.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title style="font-size: 1rem">{{
                      qlink.name
                    }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action class="delete-quick-link-div">
                    <v-icon
                      icon
                      class="delete-quick-link-btn"
                      @click.native="deleteQuickLink(qlink)"
                      style="color: grey"
                      title="delete links"
                      >delete</v-icon
                    >
                  </v-list-item-action>
                </v-list-item>
              </router-link>

              <v-divider></v-divider>
            </div>
          </v-list-item-group>
        </v-list>
      </div>
    </div>
    -->
    <!-- End of Quick Link Div --->

    <!-- Quick Link Add Model -->
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Add Quick Link
        </v-card-title>

        <v-card-text v-if="all_role_links != null">
          <v-row>
            <v-col cols="12">
              <v-combobox
                v-model="add_link"
                :items="all_role_links"
                item-text="linkname"
                item-value="id"
                label="Add Quick links"
                return-object
                multiple
                chips
                clearable
              >
              </v-combobox>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer> </v-spacer>
          <v-btn color="primary" text @click="cancelDialog()" outlined>
            Cancel
          </v-btn>
          <v-btn color="primary" @click="saveQuickLinks">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Quick Link Add Model -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "App",

  components: {},

  data: () => ({
    isClosed: true,
    dialog: false,
    quick_links: false,
    snackbar_msg: "",
    color: "",
    snackbar: false,
    quicklinkslist: null,
    all_role_links: null,
    //2
    add_link: []
  }),
  methods: {
    openDialog() {
      //location.reload();
      this.dialog = true;
    },
    cancelDialog() {
      this.dialog = false;
      this.sliderCloseOpen(true);
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },

    sliderCloseOpen(val) {
      var quickslidediv = document.getElementById("quick_link_slider");
      //console.log("sliderCloseOpen>>" + val);
      if (val) {
        quickslidediv.style.right = "-256px";
        //console.log("IF>>" + quickslidediv.style.right);
        this.isClosed = true;
      } else {
        this.isClosed = false;
        quickslidediv.style.right = "0px";
        //console.log("ELSE>>" + quickslidediv.style.right);
      }
    },

    deleteQuickLink(qlink) {
      const formData = {
        qlinkid: qlink.id
      };
      axios
        .post("roleManagement/deleteQuickLinks", formData)
        .then(res => {
          if (res.data.msg == "200") {
            this.quicklinkslist = res.data.quick_links;
            this.all_role_links = res.data.all_role_links;
            this.showSnackbar("#b71c1c", "Deleted successfully..."); // show snackbar on success
          }
        })
        .catch(error => {
          window.console.log(error);
        })
        .finally(() => {});
      //alert("Delete " + qlink.id);
    },
    saveQuickLinks() {
      if (this.add_link.length == 0) {
        //alert("Please select")
        this.showSnackbar("#b71c1c", "Please select"); // show snackbar on success
        return;
      }
      axios
        .post("roleManagement/saveQuickLinks", this.add_link)
        .then(res => {
          if (res.data.msg == "200") {
            this.quicklinkslist = res.data.quick_links;
            this.all_role_links = res.data.all_role_links;
            this.sliderCloseOpen(false);
            this.dialog = false;
            this.showSnackbar("#4caf50", "Quick Link added successfully..."); // show snackbar on success
          }
        })
        .catch(error => {
          window.console.log(error);
        })
        .finally(() => {});
    }
    //
  },
  watch: {
  //   "$store.state.quick_links": function() {
  //     this.quick_links = this.$store.state.quick_links;
  //     axios
  //       .post("roleManagement/getUserRoleLinks")
  //       .then(res => {
  //         console.log(res)
  //         if (res.data.msg == "200") {
  //           this.quicklinkslist = res.data.quick_links;
  //           this.all_role_links = res.data.all_role_links;
  //           // console.log("all_role_links>>"+this.all_role_links)
  //         }
  //       })
  //       .catch(error => {
  //         window.console.log(error);
  //       })
  //       .finally(() => {});
  //   }
  }
};
</script>
<style scoped>
.quick-link-slider-box {
  border: 1px solid #0000001f;
  position: fixed;
  top: 9%;
  right: -256px;
  width: 256px;
  height: 91%;

  background-color: white;
  transition-duration: 0.1s;
  transition-delay: 0.2s;
}
.theme--light.v-icon {
  color: orange;
}
.quick-link-icon {
  text-align: center;
  width: 28px;
  height: 28px;
  position: relative;
  left: -28px;

  transition-duration: 0.1s;
  transition-delay: 0.2s;
}
.sidebar-link {
  text-decoration: none;
}
.sidebar-link-item:hover {
  background-color: rgb(181, 186, 190) !important;
}
.quick-link-add-btn {
  position: absolute;
  right: 4px;
  top: 8px;
}
@media print {
  body {
    height: 100%;
    width: 100%;
  }
  .no-display{
    display: none !important;
  }
}
</style>
