<template>
  <v-app-bar
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    app
    dark
    class="d-print-none c-title"
  >
    <v-app-bar-nav-icon @click.stop="toggleDrawer" />
    <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
      <!-- <span class="hidden-sm-and-down">Easy Placement</span> -->
      <!-- <span class="hidden-sm-and-down">Jobz</span> -->
      <v-img
          style="width: 130px;"
          alt="School Logo"
          class="shrink"
          contain
          :src="logo_main"
          transition="scale-transition"
        />
    </v-toolbar-title>

    <v-toolbar-title style="margin-left:200px;color:yellow;font-weight:900;font-size:18px;">
      <center><span v-if="grno_empcode!=''">{{grno_empcode}} : </span>{{name}}</center>
    </v-toolbar-title>

    <v-spacer />
   <!--- <v-btn icon>
      <v-avatar>
        <v-icon>mdi-bell </v-icon>  <sup v-if="notification_list.length > 0" style="color:red;font-weight:900;font-size:15px" @click="getnotification(uid)">{{notification_list.length}}</sup>
      </v-avatar>
    </v-btn>-->

    <router-link to="/analytics-dashboard" class="sidebar-link">
      <v-icon>
        mdi mdi-view-dashboard
      </v-icon>
    </router-link>

  <div class="text-center">
      <v-menu
        v-model="notificationmenu"
        :close-on-content-click="true"
       min-width="600"
        offset-x
        max-height="600"
        transition="scroll-y-transition"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">          
            <v-avatar>
              <v-icon >mdi-bell </v-icon>  <sup v-if="notification_list.length > 0" style="color:red;font-weight:900;font-size:15px" @click="getnotification(uid)">{{notification_list.length}}</sup>
            </v-avatar>   
          </v-btn>
        </template>

        <v-card max-width="600" class="mr-5" >
       <div v-for="(i, index) in notification_list" :key="index">
        
          <div v-if="i.isread ==true" style="background-color:#CFD8DC" >
            <v-card-text ><span v-html="i.message"></span>
            </v-card-text>
           <!-- <v-card-action style="cursor:pointer;margin-left:15px;" @click="getnotificationRead(i.id)">
              Read
            </v-card-action>-->
          </div>
          <div v-else>
            <v-card-text @click="getnotificationRead(i.id)">
            <span v-html="i.message"></span>
             <!-- {{i.message}} -->
            </v-card-text>
           <!--- <v-card-action style="cursor:pointer;margin-left:15px;" @click="getnotificationRead(i.id)">
              Read
            </v-card-action>-->
          </div>     
            <div id="divider" style="background-color:#263238; height:1px; width:100%;"></div>
            
        </div>
        
        </v-card>
      </v-menu>
    </div>





 <!--- <v-dialog class="notify" v-model="dialog" max-width="500px">
     <div v-for="item in notification_list">
        <v-card >
        <v-card-text>
          {{item.message}}   
          </v-card-text>  
        </v-card>
     </div>
    </v-dialog>-->






    <div style="width: 10px"></div>
    <div class="text-center">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
        transition="scroll-y-transition"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <span v-if="profile_image_path">
              <v-avatar>
                <v-img
                  :src="awspath + profile_image_path + profile_image_name"
                ></v-img>
              </v-avatar>
            </span>
            <span v-else>
              <v-avatar color="white">
                <span class="indigo--text headline">{{ initials }}</span>
              </v-avatar>
            </span>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <span v-if="profile_image_path">
                  <v-avatar>
                    <v-img
                      :src="awspath + profile_image_path + profile_image_name"
                    ></v-img>
                  </v-avatar>
                </span>
                <span v-else>
                  <v-avatar color="white">
                    <span class="indigo--text headline">{{ initials }}</span>
                  </v-avatar>
                </span>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  >Hello, {{ data.username }}</v-list-item-title
                >
                <!-- <v-list-item-subtitle>{{ uid }}</v-list-item-subtitle> -->
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list shaped>
            <v-list-item-group color="primary">
              <!--
              <router-link to="/user-profile" class="sidebar-link">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="warning">mdi-account-circle-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>User Profile</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </router-link>
              -->

              <router-link to="/update-password" class="sidebar-link">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="secondary">mdi-account-key</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Change Password</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </router-link>
              
              <v-list-item @click="bypasslogout();" v-if="isbypasslogout=='true'">

                  <v-list-item-icon>
                    <v-icon color="secondary">mdi-history</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Bypass Logout</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
          </v-list>


          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="ma-1"
              outlined
              small
              fab
              color="error"
              @click="closeMenuAndLogout()"
              title="logout"
            >
              <v-icon>mdi-location-exit</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </div>
  </v-app-bar>
</template>
<script>
import axios from "axios";

export default {
  data: () => ({
    initials: "",
    menu: false,
    notificationmenu : false,
    username: "",
    grno_empcode:"",
    name:"",
    uid: "",
    data: {},
    profile_image_name: "",
    profile_image_path: "",
    notification_list:[],
    dialog :false,
    isbypasslogout:false,
    token:"",
    uid: "",
    tenant: "",
    usertype: "",
    bypassinstuid : "",
    logo_main:
      "https://vierp-test.s3.ap-south-1.amazonaws.com/logo/eduplus_campus_white.png",
  }),
  methods: {
   bypasslogout() {
    this.token = localStorage.getItem("EPS-tokenbackup")
    this.uid = localStorage.getItem("EPS-uidbackup")
    this.tenant = localStorage.getItem("EPS-tenantbackup")
    this.usertype = localStorage.getItem("EPS-usertypebackup")

    localStorage.setItem("EPS-token", this.token);
    localStorage.setItem("EPS-uid", this.uid);
    localStorage.setItem("EPS-tenant", this.tenant);
    localStorage.setItem("EPS-usertype", this.usertype);

    localStorage.removeItem("EPS-tokenbackup");
    localStorage.removeItem("EPS-uidbackup");
    localStorage.removeItem("EPS-tenantbackup");
    localStorage.removeItem("EPS-usertypebackup");
    localStorage.removeItem("Isbypass");
    localStorage.removeItem("instuid");

   
    this.$router.push("/home")
    window.location.reload();
    
    },
    toggleDrawer() {
      this.$store.state.drawer = !this.$store.state.drawer;
    },
    closeMenuAndLogout() {
      this.menu = false;
      if(sessionStorage.getItem("EPS-uid")!="sessionend"){
        sessionStorage.setItem("EPS-uid","sessionend");
        window.close();
        localStorage.clear();
        this.$router.push("/");
      }else{
        localStorage.clear();
        this.$router.push("/");
      }
    },
    getnotification(item){
      this.dialog = true
    },
    getnotificationRead(item){
     // alert("in getnotificationRead"+item);
      var params={
        'notification':item
      };
     
      axios
      .post("StudentApplicationTrack/getnotificationRead",params)
      .then((res) => {
        if (res.data.code == "200") {
          alert("notification Read ..!!")
        } else {
        }
      })
      .catch((error) => {
        window.console.log(error);
      });

    },
    imageReplacement(uername) {
      //var names = this.uername.split('');
      //console.log("names "+names)
      this.initials = uername[0].charAt(0).toUpperCase();
      // window.alert(initials)
    },
  },
  mounted() {
    this.isbypasslogout = localStorage.getItem("Isbypass");
    this.bypassinstuid = localStorage.getItem("instuid");
    this.$store.commit("setquick_links", true);
    this.awspath = localStorage.getItem("awspath");
    this.uid = localStorage.getItem("EPS-uid");
    this.tenant = localStorage.getItem("EPS-tenant");

    axios
      .get("login/getusername")
      .then((res) => {
        //window.console.log("res"+res.data)
        if (res.data.msg == "200") {
          this.data = res.data;
          this.uername = this.data.username;
          this.grno_empcode = this.data.grno_empcode;
          this.name = this.data.name;
          this.notification_list = res.data.notification_list
          // window.alert(this.uername)
          this.profile_image_name = this.data.profile_image_name;
          this.profile_image_path = this.data.profile_image_path;
          this.imageReplacement(this.uername);
        } else {
        }
      })
      .catch((error) => {
        window.console.log(error);
      });
  },
};
</script>
<style scoped>
.sidebar-link {
  text-decoration: none;
}
.sidebar-link-item:hover {
  background-color: rgb(181, 186, 190) !important;
}
.c-title {
  background-image: linear-gradient(-90deg, #8edffd, #034c70);
  color: #fff;
}
.notify{
 position: absolute;
  top: 0px;
  left: 0px
  }

</style>