import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router'
import { routes } from './routes'
import { store } from './store/store'
import axios from 'axios'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import Vuelidate from 'vuelidate'

import JsonExcel from 'vue-json-excel'

import VueExcelXlsx from "vue-excel-xlsx";

Vue.use(VueExcelXlsx);

import "@/plugins/echarts";

import DatetimePicker from 'vuetify-datetime-picker'
// (Optional) import 'vuetify-datetime-picker/src/stylus/main.styl'

Vue.use(DatetimePicker)

Vue.component('downloadExcel', JsonExcel)

Vue.use(Vuelidate)
Vue.use(VueRouter);


import CKEditor from 'ckeditor4-vue';

Vue.use( CKEditor );

//Vue.use( CKEditor );

const router = new VueRouter({
    routes: routes, //routes:routes
    mode: 'history', //  history to remove #
    //  linkActiveClass: 'tag is-active',
})


Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_API_URL //"http://localhost:8080"
axios.interceptors.request.use(config => {
    // console.log("req-int"+navigator.onLine)
    if (!navigator.onLine) {
        alert("You are offline!!!")
    }
   
    if (localStorage.getItem("EPS-token"))
        config.headers.common['EPS-token'] = localStorage.getItem("EPS-token")
    if (localStorage.getItem("EPS-uid"))
        config.headers.common['EPS-uid'] = localStorage.getItem("EPS-uid")
    if(localStorage.getItem("EPS-tenant"))
        config.headers.common['EPS-tenant']= localStorage.getItem("EPS-tenant")
        // if (sessionStorage.getItem("EPS-uid")!='sessionend')
        // config.headers.common['EPS-uid'] = sessionStorage.getItem("EPS-uid")
    config.headers.common['router-path'] = router.currentRoute.path
    return config
})
axios.interceptors.response.use(res => {
    // console.log("res-url:"+res.request.responseURL)
    // console.log("res-data:"+res.data)
    if (res.data.msg == "200") {
        if (res.data.token_status == "token-refreshed")
        localStorage.setItem("EPS-token", res.data.token)
    } else if (res.data.msg == "401") {
        localStorage.setItem('login-page-msg', "You are already logged in on a different device. Please login again")
        router.push("/")
    } else if (res.data.msg == "403") {
        localStorage.setItem('login-page-msg', "Access Denied!!!")
        router.push("/")
    } else if(res.data.msg=="404"){
        //alert("Session expired!!!")
        localStorage.setItem('login-page-msg',"Session expired!!!.  Please login again...")
        router.push("/")
      }
    return res
})

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')